import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core';
import UserIcon from '@material-ui/icons/PersonOutline';
import { ReactComponent as Discord } from 'assets/icons/discord.svg';
import { ReactComponent as Google } from 'assets/icons/google.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { analytics } from 'config/firebase';
import { loginAnonymously, loginWithGoogle, logout } from 'helpers/firebase/auth';
import { useAuth } from 'helpers/firebase/useAuth';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'styles/Link';
import {
    Divider,
    Links,
    LogoWrapper,
    Spacer,
    ToolbarDialog,
    TopnavBar,
    TopnavToolbar,
    VersionChip,
} from 'styles/Topnav';

import PACKAGE from '../../../package.json';
import { ProfileMenu } from './ProfileMenu';

const Topnav = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, profile, initializing } = useAuth();
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
    const [signingIn, setSigningIn] = useState(false);
    const [signingOut, setSigningOut] = useState(false);
    const [loggingInAnonimously, setLoggingInAnonimously] = useState(false);

    async function anonLogin() {
        setLoggingInAnonimously(true);
        await loginAnonymously();
        setLoggingInAnonimously(false);
        setSigningIn(false);
        enqueueSnackbar("You're officialy in Anon!");
    }

    async function googleLogin() {
        loginWithGoogle();
        setSigningIn(false);
    }

    async function signout() {
        setMenuAnchor(null);
        setSigningOut(true);
        await logout();
        setSigningOut(false);
        enqueueSnackbar('See you later!');
    }

    function handleMenuClose() {
        setMenuAnchor(null);
    }

    function handleLogout() {
        setMenuAnchor(null);
        signout();
    }

    return (
        <TopnavBar>
            <TopnavToolbar>
                <LogoWrapper to="/">
                    <Logo />
                    <Typography variant="h6">Pokédexit</Typography>
                </LogoWrapper>
                <VersionChip label={`BETA • ${PACKAGE.version}`} />
                <Spacer />
                <Links>
                    <Link to="/stats">Stats</Link>
                    <Link to="/changelog">Changelog</Link>
                    <Divider />
                    <Link href="https://trello.com/b/oTy4ALDm/pokedexit" target="_blank">
                        Roadmap
                    </Link>
                    <Link href="https://ko-fi.com/el1flem" target="_blank">
                        Buy me a coffee
                    </Link>
                    <Divider />
                    <IconButton component="a" href="https://discord.gg/54b7dKU" target="_blank">
                        <Icon>
                            <Discord />
                        </Icon>
                    </IconButton>
                    <Divider />
                </Links>
                {initializing || signingOut ? (
                    <IconButton>
                        <Avatar>
                            <CircularProgress />
                        </Avatar>
                    </IconButton>
                ) : (
                    <Tooltip title="login">
                        <IconButton
                            onClick={
                                user
                                    ? e => setMenuAnchor(e.currentTarget)
                                    : () => {
                                          setSigningIn(true);
                                          analytics.logEvent('signing_in');
                                      }
                            }
                        >
                            <Avatar src={profile ? profile.photo : ''}>
                                {user && !profile ? 'A' : <UserIcon />}
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                )}
                {profile && (
                    <ProfileMenu
                        profile={profile}
                        anchor={menuAnchor}
                        onClose={handleMenuClose}
                        onLogout={handleLogout}
                    />
                )}
            </TopnavToolbar>

            {/* Dialog */}
            <ToolbarDialog
                fullWidth
                maxWidth="xs"
                open={signingIn}
                onClose={() => setSigningIn(false)}
            >
                <DialogTitle>Let us hear your voice</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Signin to the site in order to let us know which pokémon you'll miss the
                        most. You don't even need to create an account if you don't want to.
                    </DialogContentText>
                    <Box marginTop={6} marginBottom={1}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={anonLogin}
                        >
                            {loggingInAnonimously ? (
                                <CircularProgress color="inherit" size={16} />
                            ) : (
                                'Sign in anonymously'
                            )}
                        </Button>
                    </Box>
                    <Typography align="center">
                        <strong>or</strong>
                    </Typography>
                    <Box marginTop={1} marginBottom={2} display="flex" justifyContent="center">
                        <Button
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={googleLogin}
                            startIcon={
                                <Icon fontSize="small">
                                    <Google />
                                </Icon>
                            }
                        >
                            Sign in with Google
                        </Button>
                    </Box>
                </DialogContent>
            </ToolbarDialog>
        </TopnavBar>
    );
};

export default Topnav;
