import { Box, Container, List, Typography } from '@material-ui/core';
import raw from 'raw.macro';
import React from 'react';
import Markdown from 'react-markdown';
import { Wrapper } from 'styles/Layout';
import { Link } from 'styles/Link';

import { ActionType, ChangeRecord, Credits, Version } from './styles';

const CHANGELOG = raw('../../../CHANGELOG.md') as string;

const Heading = ({
    level,
    children,
}: {
    level: '1' | '2' | '3' | '4' | '5' | '6';
    children: string;
}) => {
    const getVariant = (l: '1' | '2' | '3' | '4' | '5' | '6') =>
        `h${l}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

    return <Typography variant={getVariant(level)}>{children}</Typography>;
};

const Update = ({ children }: { children: any }) => (
    <Version>
        <List>{children}</List>
    </Version>
);

const Change = ({ children }: { children: any }) => (
    <ChangeRecord>
        <Typography>{children}</Typography>
    </ChangeRecord>
);

const Action = ({ children }: { children: any }) => {
    return (
        <div>
            <ActionType>{children[0].props.children}</ActionType>
        </div>
    );
};

const Anchor = ({ children, ...props }: any) => (
    <Credits>
        <span>
            Idea by{' '}
            <Link {...props} target="_blank">
                {children}
            </Link>
        </span>
    </Credits>
);

export const ChangeLog = () => {
    return (
        <Wrapper>
            <Container maxWidth="sm">
                <Box marginTop={8}>
                    <Markdown
                        source={CHANGELOG}
                        renderers={{
                            heading: Heading,
                            list: Update,
                            listItem: Change,
                            strong: Action,
                            link: Anchor,
                        }}
                    />
                </Box>
            </Container>
        </Wrapper>
    );
};
