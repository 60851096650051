import { ButtonBase } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { centerItem } from 'styles/mixins/flex';

export const SpriteButton = styled(ButtonBase)`
    position: relative;
    padding-top: 100%;
    border-radius: 25%;
    width: 100%;
`;

export const SpriteButtonContent = styled.div<{ active?: boolean }>`
    ${centerItem}
    border-radius: 25%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 8px;
    ${props =>
        props.active
            ? css`
                  background: ${props => props.theme.palette.background.paper};
                  box-shadow: 0 4px 24px -2px rgba(0, 0, 0, 0.1);
              `
            : css`
                  border: 4px solid
                      ${props =>
                          props.theme.palette.type === 'dark'
                              ? props.theme.palette.background.paper
                              : 'rgba(0, 0, 0, 0.2)'};
              `};
`;
