import { css } from 'styled-components';

export const solidPseudo = css`
    content: '';
    display: block;
`;

export const absoluteBlock = css`
    ${solidPseudo};
    position: absolute;
`;
