import { ListItem, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Version = styled(Paper)`
    margin-bottom: 48px;
    box-shadow: 0 4px 24px -2px rgba(0, 0, 0, 0.1);
    padding: 8px;
`;

export const ChangeRecord = styled(ListItem).attrs({ button: true, disableRipple: true })`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Work Sans;
    font-weight: 500;
    border-radius: 12px;
`;

export const ActionTypeWrapper = styled.div``;

const ActionTypeColors = {
    ADD: '#2ed573',
    DELETE: '#ff6348',
    EDIT: '#1e90ff',
    FIX: '#ffa502',
};

export const ActionType = styled.strong<{
    children: 'ADD' | 'DELETE';
}>`
    display: inline-block;
    padding: 4px 16px;
    border-radius: 8px;
    margin-bottom: 8px;
    min-width: 64px;
    text-align: center;
    background: ${props => ActionTypeColors[props.children as 'ADD' | 'DELETE']};
    color: #fff;
`;

export const Credits = styled(Typography)`
    margin-top: 8px;
    margin-bottom: 6px;

    span {
        background: rgba(0, 0, 0, 0.1);
        padding: 8px 12px;
        border-radius: 8px;
    }
`;
