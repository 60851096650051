const availableList = [
    'grookey',
    'thwackey',
    'rillaboom',
    'scorbunny',
    'raboot',
    'cinderace',
    'sobble',
    'drizzile',
    'inteleon',
    'blipbug',
    'dottler',
    'orbeetle',
    'caterpie',
    'metapod',
    'butterfree',
    'grubbin',
    'charjabug',
    'vikavolt',
    'hoothoot',
    'noctowl',
    'rookidee',
    'corvisquire',
    'corniknight',
    'skowvet',
    'greedunt',
    'pidove',
    'tranquill',
    'unfezant',
    'nickit',
    'thievul',
    'zigzagoon',
    'linoone',
    'obstagoon',
    'wooloo',
    'dubwool',
    'lotad',
    'lombre',
    'ludicolo',
    'seedot',
    'nuzleaf',
    'shiftry',
    'chewtle',
    'drednaw',
    'purrloin',
    'liepard',
    'yamper',
    'boltund',
    'bunnelby',
    'diggersby',
    'minccino',
    'cinccino',
    'bounsweet',
    'steenee',
    'tsareena',
    'oddish',
    'gloom',
    'vileplume',
    'bellossom',
    'budew',
    'roselia',
    'roserade',
    'wingull',
    'pelipper',
    'joltik',
    'galvantula',
    'electrike',
    'manectric',
    'vulpix',
    'ninetales',
    'growlithe',
    'arcanine',
    'vanillite',
    'vanillish',
    'vanilluxe',
    'swinub',
    'piloswine',
    'mamoswine',
    'delibird',
    'snorunt',
    'glalie',
    'froslass',
    'baltoy',
    'claydol',
    'mudbray',
    'mudsdale',
    'dwebble',
    'crustle',
    'golett',
    'golurk',
    'munna',
    'musharna',
    'natu',
    'xatu',
    'stufful',
    'bewear',
    'snover',
    'anomasnow',
    'krabby',
    'kingler',
    'wooper',
    'quagsire',
    'corphish',
    'crawdaunt',
    'nincada',
    'ninjask',
    'shedinja',
    'tyrogue',
    'hitmonlee',
    'hitmonchan',
    'hitmontop',
    'pancham',
    'pangoro',
    'klink',
    'klang',
    'klinklang',
    'combee',
    'vespiquen',
    'bronzor',
    'bronzong',
    'ralts',
    'kirlia',
    'gardevoir',
    'gallade',
    'drifloon',
    'drifblim',
    'gossifleur',
    'eldegoss',
    'cherubi',
    'cherrim',
    'stunky',
    'skuntank',
    'tympole',
    'palpitoad',
    'seismitoad',
    'duskull',
    'dusclops',
    'dusknoir',
    'machop',
    'machoke',
    'machamp',
    'gastly',
    'haunter',
    'gengar',
    'magikarp',
    'gyrados',
    'goldeen',
    'seaking',
    'remoraid',
    'octillery',
    'shellder',
    'cloyster',
    'feebas',
    'milotic',
    'basculin',
    'wishiwashi',
    'pyukumuku',
    'trubbish',
    'garbodor',
    'sizzlipede',
    'centiskorch',
    'rolycoly',
    'carkol',
    'coalossal',
    'diglett',
    'dugtrio',
    'drilbur',
    'excadrill',
    'roggenrola',
    'boldore',
    'gigalith',
    'timburr',
    'gurdurr',
    'conkeldurr',
    'woobat',
    'swoobat',
    'noibat',
    'noivern',
    'onix',
    'steelix',
    'arrokuda',
    'barraskewda',
    'meowth',
    'perrserker',
    'persian',
    'milcery',
    'alcremie',
    'cutiefly',
    'ribombee',
    'ferroseed',
    'ferrothorn',
    'pumpkaboo',
    'gourgeist',
    'pichu',
    'pikachu',
    'raichu',
    'eevee',
    'vaporeon',
    'jolteon',
    'flareon',
    'espeon',
    'umbreon',
    'leafeon',
    'glaceon',
    'sylveon',
    'applin',
    'flapple',
    'appletun',
    'espurr',
    'mewostic',
    'swirlix',
    'slurpuff',
    'spritzee',
    'aromatisse',
    'dewpider',
    'araquanid',
    'wynaut',
    'wobbuffet',
    'farfetch’d',
    'sirfetch’d',
    'chinchou',
    'lanturn',
    'croagunk',
    'toxicroak',
    'scraggy',
    'scrafty',
    'stunfisk',
    'shuckle',
    'barboach',
    'whiscash',
    'shellos',
    'gastrodon',
    'wimpod',
    'golisopod',
    'binacle',
    'barbaracle',
    'cosola',
    'cursola',
    'impidimp',
    'morgrem',
    'grimmsnarl',
    'hatenna',
    'hattrem',
    'hatterene',
    'salandit',
    'salazzle',
    'pawniard',
    'bisharp',
    'throh',
    'sawk',
    'koffing',
    'weezing',
    'bonsly',
    'sudowoodo',
    'cleffa',
    'clefairy',
    'clefable',
    'togepi',
    'togetic',
    'togekiss',
    'munchlax',
    'snorlax',
    'cottonee',
    'whimsicott',
    'rhyhorn',
    'rhydon',
    'rhyperior',
    'gothita',
    'gothorita',
    'gothitelle',
    'solosis',
    'duosion',
    'reuniclus',
    'karrablast',
    'escavalier',
    'shelmet',
    'accelgor',
    'elgyem',
    'beheeyem',
    'cubchoo',
    'beartic',
    'rufflet',
    'braviary',
    'vullaby',
    'mandibuzz',
    'skorupi',
    'drapion',
    'litwick',
    'lampent',
    'chandelure',
    'inkay',
    'malamar',
    'sneasel',
    'weavile',
    'sableye',
    'mawile',
    'maractus',
    'sigilyph',
    'riolu',
    'lucario',
    'torkoal',
    'mimikyu',
    'cufant',
    'copperajah',
    'qwilfish',
    'frillish',
    'jellicent',
    'mareanie',
    'toxapex',
    'cramorant',
    'toxel',
    'toxtricity',
    'silicobra',
    'sandaconda',
    'hippopotas',
    'hippowdon',
    'durant',
    'heatmor',
    'helioptile',
    'heliolisk',
    'hawlucha',
    'trapinch',
    'vibrava',
    'flygon',
    'axew',
    'fraxure',
    'haxorus',
    'yamask',
    'runerigus',
    'cofagrigus',
    'honedge',
    'doublade',
    'aegislash',
    'ponya',
    'rapidash',
    'sinistea',
    'polteageist',
    'indeedee',
    'phantump',
    'trevenant',
    'morelull',
    'shiinotic',
    'oranguru',
    'passimian',
    'morepeko',
    'falinks',
    'drampa',
    'turtonator',
    'togedemaru',
    'snom',
    'frosmoth',
    'clobbopus',
    'grapploct',
    'pinchurchin',
    'mantyke',
    'mantine',
    'wailmer',
    'wailord',
    'bergmite',
    'avalugg',
    'dhelmise',
    'lapras',
    'lunatone',
    'solrock',
    'mime jr.',
    'mime',
    'rime',
    'darumaka',
    'darmanitan',
    'stonjourner',
    'eiscue',
    'duraludon',
    'rotom',
    'ditto',
    'dracozolt',
    'arctozolt',
    'dracovish',
    'arctovish',
    'charmander',
    'charmeleon',
    'charizard',
    'type-null',
    'silvally',
    'larvitar',
    'pupitar',
    'tyranitar',
    'deino',
    'zweilous',
    'hydreigon',
    'goomy',
    'sliggoo',
    'goodra',
    'jangmo-o',
    'hakamo-o',
    'komoo-o',
    'dreepy',
    'drakloak',
    'dragapult',
    'zacian',
    'zamazenta',
    'eternatus',
];

const maybeList = [
    'bulbasaur',
    'ivysaur',
    'venusaur',
    'squirtle',
    'wartortle',
    'blastoise',
    'mewtwo',
    'mew',
    'celebi',
    'jirachi',
    'cobalion',
    'terrakion',
    'virizion',
    'reshiram',
    'zekrom',
    'kyurem',
    'keldeo',
    'rowlet',
    'dartrix',
    'decidueye',
    'litten',
    'torracat',
    'incineroar',
    'popplio',
    'brionne',
    'primarina',
    'cosmog',
    'cosmoem',
    'solgaleo',
    'lunala',
    'necrozma',
    'marshadow',
    'zeraora',
    'meltan',
    'melmetal',
];

export default function isAvailable(name: string) {
    return availableList.indexOf(name) >= 0;
}

export function isAvailableMaybe(name: string) {
    return maybeList.indexOf(name) >= 0;
}
