import ConditionalLink from 'components/ConditionalLink';
import styled from 'styled-components';

import { absoluteBlock } from '../mixins/pseudo';

export const Link = styled(ConditionalLink)`
    position: relative;
    text-decoration: none;
    color: inherit;
    transition: 0.2s all ease-in-out;
    z-index: 1;
    font-weight: 700;

    &:hover {
        color: ${props => props.theme.palette.background.default};

        &::before {
            height: calc(100% + 4px);
        }
    }

    &::before {
        ${absoluteBlock};
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
        background: ${props => props.theme.palette.text.primary};
        z-index: -1;
        transition: 0.2s all ease-in-out;
    }
`;
