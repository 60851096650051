import React, { useState } from 'react';
import { RatioBox, RatioBoxContent } from 'styles/ratioBox';
import { capitalize } from 'voca';

import { CountBadge, CountBadges, GraphBox, GraphDetails, GraphRecord } from '../styles';
import { MissingPie } from './MissingPie';

type PokemonRelevatStatData = {
    generation: number;
    present: boolean;
    leaked: boolean;
};

const getGenCount = (
    stats: {
        [groupingKeyValue: string]: {
            [key: string]: PokemonRelevatStatData;
        }[];
    },
    gen: number,
) => (stats[gen] ? stats[gen].length : 0);

export const MissingGenRecord = ({
    data,
    gen,
}: {
    data: [
        'present' | 'missing' | 'leaked',
        {
            [groupingKeyValue: string]: {
                [key: string]: PokemonRelevatStatData;
            }[];
        },
    ][];
    gen: number;
}) => {
    const [hovering, setHovering] = useState(false);
    const genTotal =
        (data[0][1][gen] || []).length +
        (data[1][1][gen] || []).length +
        (data[2][1][gen] || []).length;

    return (
        <GraphRecord onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            <GraphBox>
                <RatioBox w={1} h={1}>
                    <RatioBoxContent>
                        <MissingPie
                            small
                            data={data.map(([type, value]) => ({
                                id: type,
                                label: capitalize(type),
                                value: getGenCount(value, gen),
                            }))}
                        />
                    </RatioBoxContent>
                </RatioBox>
            </GraphBox>
            <GraphDetails>
                <strong>{`Gen ${gen}`}</strong>
                <CountBadges>
                    {data.map(([type, value]) => (
                        <CountBadge key={type} type={type}>
                            {hovering ? (
                                getGenCount(value, gen)
                            ) : (
                                <span>
                                    {Math.floor((getGenCount(value, gen) / genTotal) * 100)}
                                    <small>%</small>
                                </span>
                            )}
                        </CountBadge>
                    ))}
                </CountBadges>
            </GraphDetails>
        </GraphRecord>
    );
};
