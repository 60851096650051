import { useMediaQuery } from '@material-ui/core';
import ItemCard from 'components/ItemCard';
import { VirtualCell } from 'components/ItemCard/styles';
import { SpriteMode } from 'components/SpriteMode';
import React from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid as Grid } from 'react-window';

// import { Grid } from './styles';

type ItemCardGridProps = {
    mode: SpriteMode;
    items?: any[];
    compress?: boolean;
    onClick: (id: number, name: string) => void;
};

const Cell = ({
    id,
    style,
    name,
    sprites,
    missing,
    present,
    leaked,
    mode,
    onClick,
}: {
    id: number;
    sprites: {
        MINISPRITE: string;
        SPRITE?: string;
        MODEL?: string;
    };
    name: string;
    mode: SpriteMode;
    missing: number;
    present: boolean;
    leaked: boolean;
    onClick: (id: number, name: string) => void;
    style: any;
}) => {
    return (
        <VirtualCell style={style}>
            <ItemCard
                key={id}
                name={name}
                mode={mode}
                icons={sprites}
                missingCount={missing}
                present={present}
                leaked={leaked}
                onClick={() => onClick(id, name)}
            />
        </VirtualCell>
    );
};

const getIdByGridPosition = (col: number, row: number, colCount: number) => row * colCount + col;

const ItemCardGrid: React.FC<ItemCardGridProps> = ({ mode, items, onClick }: ItemCardGridProps) => {
    const S = useMediaQuery('(min-width:360px)');
    const M = useMediaQuery('(min-width:600px)');
    const L = useMediaQuery('(min-width:1280px)');
    const XL = useMediaQuery('(min-width:1600px)');
    const isMini = mode === 'MINISPRITE';

    const grid = {
        cols: 0,
    };

    if (XL) {
        grid.cols = isMini ? 12 : 10;
    } else if (L) {
        grid.cols = isMini ? 7 : 5;
    } else if (M) {
        grid.cols = isMini ? 5 : 4;
    } else if (S) {
        grid.cols = isMini ? 3 : 2;
    } else {
        grid.cols = 2;
    }

    return (
        <Autosizer>
            {({ height, width }) => (
                <Grid
                    columnCount={grid.cols}
                    columnWidth={width / grid.cols}
                    height={height}
                    rowCount={Math.ceil((items || []).length / grid.cols)}
                    overscanRowCount={20}
                    rowHeight={isMini ? 116 : 180}
                    width={width}
                    style={{ margin: -4 }}
                >
                    {({ columnIndex, rowIndex, style }) => {
                        const id = getIdByGridPosition(columnIndex, rowIndex, grid.cols);
                        if (!items || items.length === 0 || id >= items.length) return null;
                        return <Cell {...items[id]} style={style} mode={mode} onClick={onClick} />;
                    }}
                </Grid>
            )}
        </Autosizer>
    );
};

export default ItemCardGrid;
