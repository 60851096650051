import { auth, facebookProvider, googleProvider } from 'config/firebase';

export const loginAnonymously = () => {
    return auth.signInAnonymously();
};

export const signupWithEmail = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
};

export const loginWithEmail = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
};

export const loginWithGoogle = () => {
    auth.signInWithPopup(googleProvider);
};

export const loginWithFacebook = () => {
    auth.signInWithPopup(facebookProvider);
};

export const logout = () => {
    return auth.signOut();
};
