import { ResponsivePie } from '@nivo/pie';
import React from 'react';

type Props = {
    small?: boolean;
    data: {
        id: 'present' | 'missing' | 'leaked';
        label: string;
        value: number;
    }[];
};

export const MissingPie = ({ small, data }: Props) => (
    <ResponsivePie
        data={data}
        innerRadius={0.8}
        cornerRadius={4}
        padAngle={2}
        margin={{
            top: 0,
            right: small ? 0 : 24,
            bottom: 0,
            left: small ? 0 : 24,
        }}
        colors={{ scheme: 'nivo' }}
        borderWidth={0}
        borderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
        enableRadialLabels={false}
        slicesLabelsSkipAngle={360}
        slicesLabelsTextColor="#333333"
        animate
        motionStiffness={90}
        motionDamping={15}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                size: 4,
                padding: 2,
                stagger: true,
                background: '#7bed9f',
                color: '#2ed573',
            },
            {
                id: 'lines',
                type: 'patternLines',
                spacing: 8,
                rotation: -45,
                lineWidth: 4,
                background: '#ff7f50',
                color: '#ff6348',
            },
        ]}
        fill={[
            {
                match: {
                    id: 'present',
                },
                id: 'dots',
            },
            {
                match: {
                    id: 'missing',
                },
                id: 'lines',
            },
        ]}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                translateY: 56,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000',
                        },
                    },
                ],
            },
        ]}
    />
);
