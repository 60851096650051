import { Menu, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const Field = styled(TextField).attrs({
    InputProps: { disableUnderline: true },
})`
    height: 56px;

    & .MuiFilledInput-root {
        border-radius: 20px;
        &:focus {
            background: darken('#d3d3d3', 0.4);
        }
    }

    & .MuiFilledInput-input {
        height: 56px;
        padding-left: 16px;
        padding-bottom: 16px;
        padding-top: 26px;
        font-family: Poppins;
        font-weight: 600;
        font-size: 0.875rem;
        box-sizing: border-box;
    }

    & .MuiInputLabel {
        &-root {
            font-family: Poppins;
            font-weight: 700;
            font-size: 0.875rem;
            margin: 0;
            transform: translate(16px, 20px) scale(1);
        }

        &-shrink {
            transform: translate(16px, 12px) scale(0.6);
        }
    }
`;

export const FieldMenu = styled(Menu).attrs({
    keepMounted: true,
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
})`
    max-height: 320px;

    & .MuiMenu-paper {
        width: 100%;
        max-width: 320px;
        box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.25);
    }

    & .MuiMenuItem-root {
        height: 56px;
    }
`;
