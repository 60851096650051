import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import ContentLoader from 'react-content-loader';

export const DexLoader = () => {
    const S = useMediaQuery('(min-width:360px)');
    const M = useMediaQuery('(min-width:600px)');
    const L = useMediaQuery('(min-width:1280px)');
    const XL = useMediaQuery('(min-width:1600px)');

    const grid = {
        width: 0,
        cols: 0,
        colGap: 8,
        rowGap: 12,
    };

    if (XL) {
        grid.width = 1376;
        grid.cols = 12;
    } else if (L) {
        grid.width = 796;
        grid.cols = 7;
    } else if (M) {
        grid.width = 532;
        grid.cols = 5;
    } else if (S) {
        grid.width = 316;
        grid.cols = 3;
    } else {
        grid.width = 208;
        grid.cols = 2;
    }

    return (
        <ContentLoader
            height={1000}
            width={grid.width}
            speed={2}
            primaryColor="#ecebeb"
            secondaryColor="#d3d3d3"
        >
            {new Array(60).fill(null).map((item, index) => {
                const cellGap = (grid.width - grid.colGap * (grid.cols - 1)) / grid.cols;

                return (
                    <rect
                        key={index}
                        x={cellGap * (index % grid.cols) + grid.colGap * (index % grid.cols)}
                        y={
                            cellGap * Math.floor(index / grid.cols) +
                            grid.rowGap * Math.floor(index / grid.cols)
                        }
                        rx="16"
                        ry="16"
                        width="100"
                        height="100"
                    />
                );
            })}
        </ContentLoader>
    );
};
