import { Button, Container, Grid, IconButton, Typography } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import copy from 'clipboard-copy';
import ItemCard from 'components/ItemCard';
import { useAuth } from 'helpers/firebase/useAuth';
import { useDocument } from 'helpers/firebase/useDocument';
import { useSnackbar } from 'notistack';
import Pokedex from 'pokedex';
import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Wrapper } from 'styles/Layout';

import { UserProfile } from '../../../types/User';
import { Team } from './components/Team';
import {
    Header,
    HeaderContent,
    ProfileActions,
    ProfileBody,
    ProfileBox,
    ProfileData,
    TeamCreateButton,
    UserAvatar,
} from './styles';

export const Profile = () => {
    const match = useRouteMatch<{ uid: string }>('/trainer/:uid');
    const { enqueueSnackbar } = useSnackbar();
    const { uid } = match ? match.params : { uid: '' };
    const { fetching, data } = useDocument('users', uid);
    const { user, profile } = useAuth();

    if (!fetching && !data) return <Redirect to="/" />;

    const userData = (data as UserProfile) || profile;

    if (!userData) return null;

    const Dex = new Pokedex();

    const shareProfile = () => {
        // HACK: TS doesn't recognize navigator.share so we have to force it in.
        const Navigator = navigator as any;
        const profileUrl = user ? `https://pokedexit.aabass.net/trainer/${user.uid}` : '';
        if (Navigator.share) {
            Navigator.share({
                title: 'Pokédexit',
                text: `I miss ${(userData.missing || []).length} pokémon. What about you?`,
                url: profileUrl,
            })
                .then(() => enqueueSnackbar('Your profile was successfuly shared!'))
                .catch(() =>
                    enqueueSnackbar(
                        'There was an error while sharing your profile. Try again later!',
                    ),
                );
        } else {
            copy(profileUrl);
            enqueueSnackbar(
                "Your current browser doesn't support direct sharing. Your profile URL has been copied to your clipboard!",
                {
                    autoHideDuration: 4000,
                },
            );
        }
    };

    return (
        <Wrapper>
            <Header>
                <Container>
                    <HeaderContent>
                        <UserAvatar src={userData.photo} />
                        <ProfileData>
                            <Typography variant="h5">Hello,</Typography>
                            <Typography variant="h1">I'm {userData.displayName}</Typography>
                            <div>
                                <ProfileBox>
                                    <Typography>I miss</Typography>
                                    <Typography variant="h3">
                                        {(userData.missing || []).length} pkmn
                                    </Typography>
                                </ProfileBox>
                                <ProfileActions>
                                    <IconButton onClick={shareProfile}>
                                        <ShareIcon />
                                    </IconButton>
                                </ProfileActions>
                            </div>
                        </ProfileData>
                    </HeaderContent>
                </Container>
            </Header>
            <Container>
                {/* <ProfileBody>
                    <Typography variant="h2">My veteran teams</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Team gen={1} members={[3, 6, 9, 15, 150, 251]} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Team gen={1} members={[3, 6, 9, 15, 150, 251]} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TeamCreateButton>
                                <Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Create new team
                                    </Button>
                                </Typography>
                            </TeamCreateButton>
                        </Grid>
                    </Grid>
                </ProfileBody> */}
                <ProfileBody>
                    <Typography variant="h2">Pokémon I miss</Typography>
                    {userData.missing && (
                        <Grid container spacing={1}>
                            {userData.missing.map(missingId => {
                                const pkmn = Dex.pokemon(Number.parseInt(missingId, 0));

                                return (
                                    <Grid key={missingId} item xs={4} sm={4} md={2} lg={1}>
                                        <ItemCard
                                            displayOnly
                                            key={missingId}
                                            name={pkmn.name}
                                            mode="MINISPRITE"
                                            icons={{ MINISPRITE: (pkmn.sprites || {}).icon }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </ProfileBody>
            </Container>
        </Wrapper>
    );
};
