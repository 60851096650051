import { firestore } from 'firebase/app';
import { useEffect, useState } from 'react';

export const useDocument = (collection: string, document: string) => {
    const [docState, setDocState] = useState<{
        fetching: boolean;
        data: firestore.DocumentData | null;
        ref: firestore.DocumentReference | null;
        error: Error | null;
    }>({
        fetching: true,
        data: null,
        ref: null,
        error: null,
    });

    const ref = document
        ? firestore()
              .collection(collection)
              .doc(document)
        : null;

    useEffect(() => {
        if (!ref) {
            setDocState({
                fetching: true,
                data: null,
                ref: null,
                error: new Error('NO_DOCUMENT_ID_PROVIDED'),
            });
            return () => null;
        }

        return ref.onSnapshot(
            doc => {
                setDocState({
                    fetching: false,
                    data: doc.data() || null,
                    ref,
                    error: null,
                });
            },
            (e: Error) => console.log(e),
        );
    }, []);

    return docState;
};
