import styled from 'styled-components';

export const RatioBox = styled.div<{ w: number; h: number }>`
    position: relative;
    width: 100%;
    padding-top: ${({ w, h }) => (w / h) * 100}%;
`;

export const RatioBoxContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;
