import Topnav from 'components/Topnav';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ChangeLog } from './Changelog';
import { Home } from './Home';
import { Profile } from './Profile';
import { Stats } from './Stats';

const Routes: React.FC = () => {
    return (
        <Router>
            <Topnav />
            <Switch>
                <Route path="/changelog" component={ChangeLog} />
                <Route path="/stats" component={Stats} />
                <Route path="/trainer/:uid" component={Profile} />
                <Route path="/profile" component={Profile} />
                <Route path="/" component={Home} />
            </Switch>
        </Router>
    );
};

export default Routes;
