import {
    Button,
    Checkbox,
    Drawer,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Toolbar,
    Typography,
} from '@material-ui/core';
import ItemCardGrid from 'components/ItemCardGrid';
import { DexLoader } from 'components/Placeholders';
import { SpriteMode } from 'components/SpriteMode';
import { analytics, firestore } from 'config/firebase';
import TYPES from 'data/type.json';
import { useAuth } from 'helpers/firebase/useAuth';
import { useDocument } from 'helpers/firebase/useDocument';
import isAvailable, { isAvailableMaybe } from 'helpers/isAvailable';
import { useSnackbar } from 'notistack';
import Pokedex from 'pokedex';
import React, { useState } from 'react';
import { Field } from 'styles/fields';
import { Wrapper } from 'styles/Layout';
import { Link } from 'styles/Link';
import { Spacer } from 'styles/Topnav';
import voca from 'voca';

import {
    DrawerContent,
    FilterBar,
    HomeContainer,
    IconBrowser,
    Sidebar,
    SidebarBlock,
    SidebarBlockTitle,
    SidebarBody,
    SidebarFooter,
    Title,
} from './styles';

type FilterType = 'generation' | 'type' | 'missing';
type Order = {
    field: 'id' | 'name' | 'missing' | 'type';
    direction: 'ASC' | 'DESC';
};
type Filters = {
    generation: number | null;
    type: string | null;
    missing: 'all' | 'only-present' | 'only-missing';
};

const Aside = ({
    query,
    order,
    filters,
    spriteMode,
    onSpriteModeChange,
    onQueryChange,
    onOrderChange,
    onFilterChange,
}: {
    query: string;
    order: Order;
    filters: Filters;
    spriteMode: SpriteMode;
    onSpriteModeChange: (value: SpriteMode) => void;
    onQueryChange: (value: string) => void;
    onOrderChange: (type: 'field' | 'direction', value: string) => void;
    onFilterChange: (type: FilterType, value: any) => void;
}) => (
    <>
        <SidebarBody>
            <Field
                label="Search for"
                variant="filled"
                onChange={e => onQueryChange(e.target.value)}
                value={query}
                fullWidth
            />
            <SidebarBlock>
                <SidebarBlockTitle>Display mode</SidebarBlockTitle>
                <SpriteMode value={spriteMode} onChange={onSpriteModeChange} />
            </SidebarBlock>
            <SidebarBlock>
                <SidebarBlockTitle>Sorting order</SidebarBlockTitle>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Field
                            label="Field"
                            variant="filled"
                            onChange={e => onOrderChange('field', e.target.value)}
                            value={order.field}
                            select
                            fullWidth
                        >
                            <MenuItem value="id">Dex #</MenuItem>
                            <MenuItem value="name">Alphabetically</MenuItem>
                            <MenuItem value="missing">Popularity</MenuItem>
                            <MenuItem value="type">Type</MenuItem>
                        </Field>
                    </Grid>
                    <Grid item xs={5}>
                        <Field
                            label="Direction"
                            variant="filled"
                            onChange={e => onOrderChange('direction', e.target.value)}
                            value={order.direction}
                            select
                            fullWidth
                        >
                            <MenuItem value="ASC">ASC</MenuItem>
                            <MenuItem value="DESC">DESC</MenuItem>
                        </Field>
                    </Grid>
                </Grid>
            </SidebarBlock>
            <SidebarBlock>
                <SidebarBlockTitle>Filters</SidebarBlockTitle>
                <Field
                    label="Generation"
                    variant="filled"
                    margin="dense"
                    onChange={e => onFilterChange('generation', e.target.value)}
                    value={filters.generation || ''}
                    select
                    fullWidth
                >
                    {new Array(8).fill(null).map((none, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                            {`Gen ${index + 1}`}
                        </MenuItem>
                    ))}
                </Field>
                <Field
                    label="Type"
                    variant="filled"
                    margin="dense"
                    onChange={e => onFilterChange('type', e.target.value)}
                    value={filters.type || ''}
                    // TODO: get rid of this ASAP
                    SelectProps={{
                        MenuProps: {
                            style: {
                                maxHeight: 320,
                            },
                        },
                    }}
                    select
                    fullWidth
                >
                    {TYPES.map(({ name }) => (
                        <MenuItem key={name} value={name}>
                            {voca.capitalize(name)}
                        </MenuItem>
                    ))}
                </Field>
            </SidebarBlock>
            <SidebarBlock>
                <SidebarBlockTitle>Presence in Galar</SidebarBlockTitle>
                <RadioGroup
                    name="missing"
                    value={filters.missing}
                    onChange={e => onFilterChange('missing', e.target.value)}
                >
                    <FormControlLabel value="all" control={<Radio />} label="Show all" />
                    <FormControlLabel
                        value="only-present"
                        control={<Radio />}
                        label="Only show present"
                    />
                    <FormControlLabel
                        value="only-missing"
                        control={<Radio />}
                        label="Only show missing"
                    />
                </RadioGroup>
            </SidebarBlock>
        </SidebarBody>
        <SidebarFooter>
            <SidebarBlock>
                <SidebarBlockTitle>Credits</SidebarBlockTitle>
                <Typography>
                    Created by{' '}
                    <Link target="_blank" href="https://ayoub.aabass.net/">
                        @el1flem
                    </Link>
                </Typography>
            </SidebarBlock>
            <SidebarBlock>
                <SidebarBlockTitle>Special Thanks</SidebarBlockTitle>
                <ul>
                    <li>
                        <Typography>
                            Pokedex •{' '}
                            <Link target="_blank" href="https://contolini.com/">
                                Chris Contolini
                            </Link>
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Sprites by{' '}
                            <Link target="_blank" href="https://pokeapi.co/">
                                PokeApi
                            </Link>{' '}
                            •{' '}
                            <Link target="_blank" href="https://phalt.github.io/">
                                Paul Hallet
                            </Link>
                        </Typography>
                    </li>
                </ul>
            </SidebarBlock>
        </SidebarFooter>
    </>
);

export const Home = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, profile, profileRef } = useAuth();
    const { fetching, data: votes } = useDocument('aggregate_stats', 'votes');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [query, setQuery] = useState('');
    const [order, setOrder] = useState<Order>({
        field: 'id',
        direction: 'ASC',
    });
    const [filters, setFilters] = useState<Filters>({
        generation: null,
        type: null,
        missing: 'all',
    });
    const [spriteMode, setSpriteMode] = useState<SpriteMode>('MINISPRITE');
    const Dex = new Pokedex();

    async function onMiss(id: number, name?: string) {
        if (!profileRef || !profile || !user) return;
        if (profile.missing && profile.missing.indexOf(id.toString()) >= 0) {
            enqueueSnackbar("You'll already miss this darling. All of us will.");
            return;
        }
        analytics.logEvent('vote', {
            user: user.uid,
            anonymous: user.isAnonymous,
            pokemon: name,
        });
        enqueueSnackbar("We're bringing this pokémon your message. This will take just a little.");
        profileRef.update({ missing: [...(profile.missing || []), id] });

        await firestore
            .collection('aggregate_stats')
            .doc('votes')
            .update({
                [id]: votes && votes[id] ? votes[id] + 1 : 1,
            });
        enqueueSnackbar(`${name} will miss you too buddy.`);
    }

    const updateOrder = (type: 'field' | 'direction', value: string) => {
        setOrder({ ...order, [type]: value });
    };

    const updateFilters = (type: FilterType, value: any) => {
        setFilters({ ...filters, [type]: value });
    };

    const pokemons = new Array(809).fill(null).map((item, index) => {
        const { id, name, sprites: originalSprites, type, generation, family } = Dex.pokemon(
            index + 1,
        );

        const sprites = {
            MINISPRITE: originalSprites.icon,
            SPRITE: originalSprites.sprite.normal,
            MODEL: originalSprites.model,
        };

        return {
            id,
            name,
            sprites,
            missing: votes && votes[id] ? (votes[id] as number) : 0,
            type,
            generation,
            family,
            present: isAvailable(name),
            leaked: isAvailableMaybe(name),
        };
    });

    const processedPokemon = pokemons
        .filter(({ name, family, generation, type, present }) => {
            if (family.indexOf(query.toLowerCase()) < 0) return false;
            if (filters.generation !== null && generation !== filters.generation) return false;
            if (filters.type !== null && (type[0] !== filters.type && type[1] !== filters.type))
                return false;
            if (filters.missing === 'only-missing' && present) return false;
            if (filters.missing === 'only-present' && !present) return false;
            return true;
        })
        .sort((a, b) => {
            const isAsc = order.direction === 'ASC';
            if (order.field === 'id') {
                if (isAsc) return 0;
                return b.id - a.id;
            }
            if (order.field === 'missing') {
                if (a.present && !b.present) return 1;
                if (!a.present && b.present) return -1;
                if (!a.present && !b.present)
                    return isAsc ? a.missing - b.missing : b.missing - a.missing;
                return 0;
            }
            if (order.field === 'name') {
                if (a.name > b.name) return isAsc ? 1 : -1;
                if (b.name > a.name) return isAsc ? -1 : 1;
                return 0;
            }
            if (order.field === 'type') {
                if (a.type[0] > b.type[0]) return isAsc ? 1 : -1;
                if (b.type[0] > a.type[0]) return isAsc ? -1 : 1;
                if (a.type[0] === b.type[0]) {
                    if (a.type[1] > b.type[1]) return isAsc ? 1 : -1;
                    if (b.type[1] > a.type[1]) return isAsc ? -1 : 1;
                }
                return 0;
            }
            return 0;
        });

    return (
        <Wrapper>
            <HomeContainer>
                <Drawer open={openDrawer} anchor="right" onClose={() => setOpenDrawer(false)}>
                    <DrawerContent>
                        <Aside
                            query={query}
                            order={order}
                            filters={filters}
                            spriteMode={spriteMode}
                            onQueryChange={setQuery}
                            onOrderChange={updateOrder}
                            onFilterChange={updateFilters}
                            onSpriteModeChange={setSpriteMode}
                        />
                    </DrawerContent>
                </Drawer>
                <Sidebar>
                    <Aside
                        query={query}
                        order={order}
                        filters={filters}
                        spriteMode={spriteMode}
                        onQueryChange={setQuery}
                        onOrderChange={updateOrder}
                        onFilterChange={updateFilters}
                        onSpriteModeChange={setSpriteMode}
                    />
                </Sidebar>
                <IconBrowser>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <FilterBar>
                            <Toolbar disableGutters>
                                <Spacer />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenDrawer(true)}
                                >
                                    Show filters & more
                                </Button>
                            </Toolbar>
                        </FilterBar>
                        <Title>
                            <Typography variant="h1">Because all pokémon matter.</Typography>
                            <Typography>
                                Let your favourites know that the Galar region will feel devoid of
                                its soul without them.
                            </Typography>
                        </Title>
                        {fetching ? (
                            <DexLoader />
                        ) : (
                            <article style={{ width: '100%', flexGrow: 1, marginBottom: -28 }}>
                                <ItemCardGrid
                                    mode={spriteMode}
                                    items={processedPokemon}
                                    onClick={onMiss}
                                />
                            </article>
                        )}
                    </div>
                </IconBrowser>
            </HomeContainer>
        </Wrapper>
    );
};
