import { Avatar, ButtonBase, Card, Paper } from '@material-ui/core';
import styled from 'styled-components';
import { centerItem, flexColumn } from 'styles/mixins/flex';

export const Header = styled.div`
    position: relative;
    background: ${props => props.theme.palette.background.paper};
    padding: 64px 0;
    border-radius: 0 0 48px 48px;

    ${props => props.theme.breakpoints.down('sm')} {
        padding: 24px 0;
    }
`;

export const UserAvatar = styled(Avatar)`
    height: 224px;
    width: 224px;
    border-radius: 35%;
    margin-right: 48px;

    ${props => props.theme.breakpoints.down('sm')} {
        height: 128px;
        width: 128px;
        margin-right: 0;
        margin-bottom: 24px;
    }
`;

export const HeaderContent = styled.div`
    display: flex;

    ${props => props.theme.breakpoints.down('sm')} {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

export const ProfileData = styled.div`
    ${flexColumn};
    justify-content: flex-end;

    h5 {
        margin: 0;
    }
`;

export const ProfileBody = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;
`;

export const ProfileBox = styled.div`
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    padding: 12px 20px;
    border-radius: 12px;

    ${props => props.theme.breakpoints.up('md')} {
        margin-right: 16px;
    }

    h3 {
        margin: 0;
    }
`;

export const ProfileActions = styled.div`
    display: inline-block;

    ${props => props.theme.breakpoints.down('sm')} {
        display: block;
        margin-top: 12px;
    }
`;

export const TeamCard = styled(Card)`
    border-radius: 20px;
    box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.1);
    padding: 16px;

    & .MuiTypography-h5 {
        margin-bottom: 0;
    }
`;

export const TeamMemberBox = styled.div`
    ${centerItem};
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    img {
        display: block;
        width: 80%;
    }
`;

export const TeamCreateButton = styled.div`
    ${centerItem};
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 16px;
`;
