import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';

import * as firebase from 'firebase/app';

const config = {
    apiKey: 'AIzaSyBRCs4qA6CfUjcdpQSp5wBG38Ch657bFN0',
    authDomain: 'pokedexit.firebaseapp.com',
    databaseURL: 'https://pokedexit.firebaseio.com',
    projectId: 'pokedexit',
    storageBucket: 'pokedexit.appspot.com',
    messagingSenderId: '787199899442',
    appId: '1:787199899442:web:6787de31643a344ce9c92b',
    measurementId: 'G-5N5TLWHTWD',
};

firebase.initializeApp(config);

firebase.auth().useDeviceLanguage();
firebase.performance();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const app = firebase.app();
export const auth = firebase.auth();
export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
