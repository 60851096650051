import { useState } from 'react';

export function useLocalStorageString(key: string, initialValue: string) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item || initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value: string) => {
        try {
            setStoredValue(value);
            window.localStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue] as [string, (value: string) => void];
}
