import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

declare module '@material-ui/core/styles/createTypography' {}

const capitalize = (): 'capitalize' => 'capitalize';
const dark = (): 'dark' => 'dark';

const baseTheme = {
    palette: {
        primary: {
            main: '#111111',
        },
        secondary: {
            main: '#000000',
        },
        background: {
            default: '#f5f5f5',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Poppins',
        h1: {
            fontSize: '2rem',
            lineHeight: '2.5rem',
            fontWeight: 800,
            marginBottom: '2rem',
        },
        h2: {
            fontSize: '1.8rem',
            lineHeight: '2.2rem',
            fontWeight: 700,
            marginBottom: '2rem',
        },
        h3: {
            fontSize: '1.6rem',
            lineHeight: '2rem',
            fontWeight: 700,
            marginBottom: '2rem',
        },
        h5: {
            fontSize: '1.2rem',
            fontWeight: 700,
            marginBottom: '1rem',
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 700,
        },
        body1: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
        },
        button: {
            textTransform: capitalize(),
            fontSize: '.85rem',
            fontWeight: 700,
        },
    },
    shape: {
        borderRadius: 12,
    },
    overrides: {
        MuiButton: {
            outlined: {
                borderWidth: 4,
            },
            sizeLarge: {
                height: 48,
            },
        },
    },
};

const baseThemeDark = {
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        type: dark(),
        background: {
            ...baseTheme.palette.background,
            default: '#303030',
        },
    },
};

const xs = {
    ...baseTheme,
};
const sm = {
    ...xs,
};
const md = {
    ...sm,
};
const lg = {
    ...md,
};
const xl = {
    ...lg,
};

const xsDark = {
    ...baseThemeDark,
};
const smDark = {
    ...xsDark,
};
const mdDark = {
    ...smDark,
};
const lgDark = {
    ...mdDark,
};
const xlDark = {
    ...lgDark,
};

const themes = {
    LIGHT: {
        xs,
        sm,
        md,
        lg,
        xl,
    },
    DARK: {
        xs: xsDark,
        sm: smDark,
        md: mdDark,
        lg: lgDark,
        xl: xlDark,
    },
};

function getTheme(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl', theme: 'DARK' | 'LIGHT') {
    const selectTheme = (bp: 'xs' | 'sm' | 'md' | 'lg' | 'xl', t: 'DARK' | 'LIGHT') =>
        themes[t][bp];

    return createMuiTheme(selectTheme(breakpoint, theme));
}

export default getTheme;
