import { Typography } from '@material-ui/core';
import { SpriteMode } from 'components/SpriteMode';
import styled from 'styled-components';

export const VirtualCell = styled.div`
    padding: 4px;
`;

export const Icon = styled.div<{ mode: SpriteMode }>`
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    height: ${props => (props.mode === 'MINISPRITE' ? 32 : 96)}px;
    width: ${props => (props.mode === 'MINISPRITE' ? 32 : 96)}px;

    img {
        display: block;
        max-height: ${props => (props.mode === 'MINISPRITE' ? 32 : 96)}px;
        max-width: ${props => (props.mode === 'MINISPRITE' ? 32 : 96)}px;
    }
`;

export const Card = styled.div`
    background: ${props => props.theme.palette.background.paper};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 12px 8px 12px;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
    }
`;

export const Name = styled(Typography)`
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 700;
    margin-top: auto;
    margin-bottom: auto;
    text-transform: capitalize;
`;

const getColor = (leaked?: boolean, present?: boolean) => {
    if (present) return '#2ecc71';
    if (leaked) return '#fbc531';
    return '#f0932b';
};

export const Status = styled.div<{ present?: boolean; leaked?: boolean }>`
    margin-top: 8px;
    background-color: ${({ present, leaked }) => getColor(leaked, present)};
    color: ${props => (props.present ? '#fff' : '#131313')};
    padding: 4px 12px;
    font-size: 0.65rem;
    font-weight: 600;
    border-radius: 8px;
`;
