import { auth } from 'config/firebase';
import firebase, { firestore } from 'firebase/app';
import React from 'react';

import { UserProfile } from '../../../types/User';

export const useAuth = () => {
    const [error, setError] = React.useState<Error | null>(null);
    const [state, setState] = React.useState<{
        initializing: boolean;
        user: firebase.User | null;
        profile: UserProfile | null;
        profileRef: firestore.DocumentReference | null;
    }>({
        initializing: true,
        user: null,
        profile: null,
        profileRef: null,
    });

    function onChange(
        initializing: boolean,
        profile: UserProfile | null,
        profileRef: firestore.DocumentReference | null,
    ) {
        const user = auth.currentUser;

        setState({
            initializing,
            user,
            profile,
            profileRef,
        });
    }

    React.useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(user => {
            onChange(true, null, null);
            if (user) onChange(false, state.profile, null);
            else onChange(false, null, null);
        });

        if (!state.user) {
            onChange(false, null, null);
            return () => null;
        }

        const ref = firebase
            .firestore()
            .collection('users')
            .doc(state.user.uid);

        onChange(true, null, null);
        const unsubscribeProfile = ref.onSnapshot(
            doc => {
                const profileSnapshot = doc.data() as UserProfile;
                onChange(false, profileSnapshot, ref);
            },
            err => {
                setError(err);
            },
        );

        return () => {
            unsubscribeAuth();
            if (unsubscribeProfile) unsubscribeProfile();
        };
    }, [state.user]);

    return {
        error,
        user: state.user,
        profile: state.profile,
        profileRef: state.profileRef,
        initializing: state.initializing,
    };
};
