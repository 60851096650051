import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'styles/Link';
import { flexColumn } from 'styles/mixins/flex';

export const HomeContainer = styled.div`
    padding: 32px 24px 24px 24px;

    @media (max-width: 420px) {
        padding: 16px 24px 24px 24px;
    }

    @media (max-width: 959px) {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    @media (min-width: 960px) {
        display: grid;
        grid-template-columns: 248px 1fr;
    }

    @media (min-width: 1280px) {
        grid-template-columns: 288px 1fr;
    }
`;

export const IconBrowser = styled.div`
    display: flex;
    flex-grow: 1;
`;

export const Title = styled.hgroup`
    margin-bottom: 32px;

    @media (max-width: 420px) {
        margin-top: 16px;
    }

    h1 {
        margin-bottom: 12px;
    }
`;

export const FilterBar = styled.div`
    @media (min-width: 961px) {
        display: none;
    }
`;

export const DrawerContent = styled.div`
    ${flexColumn}
    width: 320px;
    height: 100%;
    padding: 24px 20px 20px;
`;

export const Sidebar = styled.aside`
    ${flexColumn}
    min-width: 224px;
    height: calc(100vh - 120px);
    position: sticky;
    top: 96px;
    padding-right: 12px;

    @media (max-width: 960px) {
        display: none;
    }

    @media (min-width: 1280px) {
        min-width: 256px;
        padding-right: 16px;
    }
`;

export const SidebarBlock = styled.div`
    margin-top: 24px;
`;

export const SidebarBlockTitle = styled(Typography)`
    font-weight: 700;
    margin-bottom: 12px;
`;

export const SidebarBody = styled.div`
    min-height: 0;
    flex-grow: 1;
    flex-basis: 1;
    overflow: auto;
    margin-top: -32px;
    margin-left: -24px;
    padding-left: 24px;
    padding-top: 32px;
    padding-bottom: 16px;

    @media (min-width: 961px) {
        padding-right: 12px;
    }

    @media (min-width: 1280px) {
        padding-right: 20px;
    }
`;

export const SidebarFooter = styled.footer`
    background: #131313;
    padding: 8px 20px 16px 20px;
    margin: 0 -20px -20px -20px;

    @media (min-width: 961px) {
        padding: 8px 20px 16px 20px;
        margin: 0 0 -24px -24px;
        border-top-right-radius: 20px;
    }

    ul {
        margin: 0;
        padding-left: 18px;
        color: #fff;
    }

    p {
        font-size: 0.8rem;
        font-weight: 500;
        color: #fff;
    }

    ${SidebarBlock} {
        margin-top: 12px;
    }

    ${SidebarBlockTitle} {
        font-weight: 700;
        margin-bottom: 4px;
    }

    ${Link} {
        &:hover {
            color: #131313;
        }

        &::before {
            background: #fff;
        }
    }
`;

export const Grid = styled.div<{ compress: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    @media (min-width: 360px) {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
    @media (min-width: 600px) {
        grid-template-columns: repeat(${props => (props.compress ? 3 : 5)}, minmax(100px, 1fr));
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(${props => (props.compress ? 4 : 7)}, minmax(100px, 1fr));
    }
    @media (min-width: 1600px) {
        grid-template-columns: repeat(${props => (props.compress ? 7 : 10)}, minmax(100px, 1fr));
    }
`;
