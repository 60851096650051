import { createContext, useContext } from 'react';

export const userContext = createContext<{
    user: null | firebase.User;
}>({
    user: null,
});

export const useSession = () => {
    const { user } = useContext(userContext);
    return user;
};
