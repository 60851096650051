import { Avatar, Button, Divider, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import ThemeIcon from '@material-ui/icons/FormatPaintOutlined';
import UserIcon from '@material-ui/icons/PersonOutlineOutlined';
import { themeContext } from 'helpers/themeContext';
import React from 'react';
import { Link } from 'react-router-dom';

import { UserProfile } from '../../../../types/User';
import { MenuButtonItem, MenuHeader, MenuItemContents, MenuWrapper } from './styles';

type Props = {
    profile: UserProfile;
    anchor: HTMLButtonElement | null;
    onClose: () => void;
    onLogout: () => void;
};

export const ProfileMenu: React.FC<Props> = ({ profile, anchor, onClose, onLogout }: Props) => {
    return (
        <MenuWrapper anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
            <MenuHeader>
                <Avatar src={profile.photo} />
                <Typography variant="h6">{profile.displayName}</Typography>
                <Typography>
                    <small>{profile.email}</small>
                </Typography>
            </MenuHeader>
            <Divider />
            <MenuItem component={Link} to="/profile">
                <MenuItemContents>
                    <ListItemIcon>
                        <UserIcon />
                    </ListItemIcon>
                    <Typography>Your profile</Typography>
                </MenuItemContents>
            </MenuItem>
            <themeContext.Consumer>
                {({ theme, setTheme }) => (
                    <MenuItem onClick={setTheme}>
                        <MenuItemContents>
                            <ListItemIcon>
                                <ThemeIcon />
                            </ListItemIcon>
                            <div>
                                <Typography>Toggle theme</Typography>
                                <Typography>
                                    <small>Dark theme</small>
                                </Typography>
                            </div>
                        </MenuItemContents>
                    </MenuItem>
                )}
            </themeContext.Consumer>
            <MenuButtonItem onClick={onLogout}>
                <Button variant="outlined">Logout</Button>
            </MenuButtonItem>
        </MenuWrapper>
    );
};
