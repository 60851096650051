import { Tooltip } from '@material-ui/core';
import { SpriteMode } from 'components/SpriteMode';
import React, { useState } from 'react';

import { Card, Icon, Name, Status } from './styles';

type Props = {
    icons: {
        MINISPRITE: string;
        SPRITE?: string;
        MODEL?: string;
    };
    name: string;
    mode: SpriteMode;
    missingCount?: number;
    present?: boolean;
    leaked?: boolean;
    displayOnly?: boolean;
    onClick?: () => void;
};

const ItemCard = ({
    icons,
    mode,
    name,
    missingCount,
    present,
    leaked,
    displayOnly,
    onClick,
}: Props) => {
    const missMessage =
        mode === 'MINISPRITE' ? `${missingCount} miss u` : `Missed by ${missingCount}`;
    const presentMessage = present ? 'Present' : 'Leaked';

    return (
        <Card onClick={present || leaked ? () => null : onClick}>
            <Icon mode={mode}>
                <img src={icons[mode]} alt={`${name} sprite`} />
            </Icon>
            <Name>{name}</Name>
            {displayOnly || (
                <Tooltip
                    title={
                        leaked
                            ? "Some leaks suggest this pokémon is actually present but it doesn't seem to be confirmed yet. Until confirmation, it will be impossible to vote for this pokémon."
                            : ''
                    }
                >
                    <Status present={present} leaked={leaked}>
                        {!present && !leaked ? missMessage : presentMessage}
                    </Status>
                </Tooltip>
            )}
        </Card>
    );
};

export default React.memo(ItemCard);
