import { Card, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { centerItem, flexColumn } from 'styles/mixins/flex';

export const StatCard = styled(Card)`
    padding: 4px 16px;
    border-radius: 20px;
    box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.1);

    & .MuiTypography-h5 {
        margin-bottom: 0;
    }

    & .MuiTypography-h6 {
        margin-bottom: 16px;
    }
`;

const colors = {
    present: '#7bed9f',
    missing: '#ff7f50',
    leaked: '#eccc68',
};

export const GraphBox = styled.div`
    width: 64px;
    height: 64px;

    ${props => props.theme.breakpoints.down('xs')} {
        width: 56px;
        margin-bottom: 4px;
    }

    ${props => props.theme.breakpoints.up('sm')} {
        margin-right: 16px;
    }
`;

export const GraphRecord = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 12px;
    transition: 0.2s;

    &:hover {
        background-color: ${props => props.theme.palette.background.paper};
        transform: translateY(-2px);
    }

    ${props => props.theme.breakpoints.down('xs')} {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 16px;
    }
`;

export const GraphDetails = styled.div`
    flex-grow: 1;
    max-width: 128px;
    width: 100%;
    pointer-events: none;
`;

export const GraphCore = styled.div`
    ${flexColumn};
    ${centerItem};
    height: 100%;
`;

export const CountBadges = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4px;
    margin-top: 4px;
    width: 100%;
    max-width: 160px;
`;

export const CountBadge = styled.div<{ type: 'present' | 'missing' | 'leaked' }>`
    display: inline-block;
    background-color: ${({ type }) => colors[type]};
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
    border-radius: 6px;
    margin-top: 4px;
    margin-right: 8px;
    padding: 2px 0;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
`;

export const PieBox = styled.div`
    background-color: ${props => props.theme.palette.background.default};
    margin-top: auto;
    padding: 16px;
    border-radius: 24px;
`;

export const Legend = styled.div`
    display: flex;
`;

export const LegendItem = styled(Typography)`
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-weight: 600;
    background-color: ${props => props.theme.palette.background.default};
    padding: 4px 12px 4px 8px;
    border-radius: 12px;
`;

export const LegendBullet = styled.div<{ type: 'present' | 'missing' | 'leaked' }>`
    background-color: ${({ type }) => colors[type]};
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 8px;
`;
