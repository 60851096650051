import { Grid } from '@material-ui/core';
import React from 'react';

import { SpriteButton, SpriteButtonContent } from './styles';

const ButtonConfig = [
    {
        value: 'MINISPRITE',
        src: 'https://img.pokemondb.net/sprites/sun-moon/icon/bulbasaur.png',
        height: 32,
    },
    {
        value: 'SPRITE',
        src: 'https://img.pokemondb.net/sprites/black-white/normal/bulbasaur.png',
        height: 64,
    },
    {
        value: 'MODEL',
        src: 'https://img.pokemondb.net/sprites/x-y/normal/bulbasaur.png',
        height: 64,
    },
] as SpriteData[];

export type SpriteMode = 'MINISPRITE' | 'SPRITE' | 'MODEL';

type SpriteData = {
    value: SpriteMode;
    src: string;
    height: number;
};

type Props = {
    value: SpriteMode;
    onChange: (value: SpriteMode) => void;
};

export const SpriteMode: React.FC<Props> = ({ value, onChange }: Props) => {
    return (
        <Grid container spacing={2}>
            {ButtonConfig.map(({ value: btnValue, src, height }) => (
                <Grid key={btnValue} item xs>
                    <SpriteButton onClick={() => onChange(btnValue)}>
                        <SpriteButtonContent active={value === btnValue}>
                            <img src={src} alt={btnValue} height={height} />
                        </SpriteButtonContent>
                    </SpriteButton>
                </Grid>
            ))}
        </Grid>
    );
};
