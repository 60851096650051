import { Menu } from '@material-ui/core';
import styled from 'styled-components';
import { centerItem, flexColumn } from 'styles/mixins/flex';

export const MenuWrapper = styled(Menu).attrs({
    keepMounted: true,
    id: 'profile-menu',
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
})`
    small {
        opacity: 0.6;
    }

    & .MuiMenu-paper {
        width: 100%;
        max-width: 320px;
        box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.25);
    }

    & .MuiMenuItem-root {
        height: 56px;
    }

    & .MuiListItemIcon-root {
        min-width: 40px;
        text-align: center;
    }
`;

export const MenuItemContents = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 224px;
    margin: 0 auto;

    p {
        font-size: 1rem;
        line-height: 1rem;
    }

    small {
        display: block;
        margin-top: 4px;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
`;

export const MenuButtonItem = styled.div`
    ${centerItem}
    cursor: default;
    height: 56px;

    &:hover,
    &:active,
    &:focus {
        background: transparent;
    }
`;

export const MenuHeader = styled.header`
    ${flexColumn}
    ${centerItem}
    height: 192px;

    & .MuiAvatar-root {
        height: 80px;
        width: 80px;
        margin-bottom: 12px;
    }
`;
