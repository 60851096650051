import { CssBaseline, MuiThemeProvider, withWidth } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { useAuth } from 'helpers/firebase/useAuth';
import { userContext } from 'helpers/firebase/useSession';
import { useLocalStorageString } from 'helpers/localstorage/useLocalStorage';
import { themeContext } from 'helpers/themeContext';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import * as serviceWorker from './config/serviceWorker';
import getTheme from './config/theme';
import Routes from './routes';

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}

const App = ({ width }: { width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }) => {
    const { user } = useAuth();
    const [theme, setThemeStorage] = useLocalStorageString('theme', 'LIGHT');
    const [themeState, setThemeState] = useState<'LIGHT' | 'DARK'>(theme as 'LIGHT' | 'DARK');

    return (
        <userContext.Provider value={{ user }}>
            <themeContext.Provider
                value={
                    {
                        theme,
                        setTheme: () => setThemeStorage(themeState === 'LIGHT' ? 'DARK' : 'LIGHT'),
                    } as {
                        theme: 'LIGHT' | 'DARK';
                        setTheme: () => void;
                    }
                }
            >
                <StylesProvider injectFirst>
                    <themeContext.Consumer>
                        {({ theme: activeTheme }) => (
                            <MuiThemeProvider theme={getTheme(width, activeTheme)}>
                                <ThemeProvider theme={getTheme(width, activeTheme)}>
                                    <SnackbarProvider
                                        autoHideDuration={2000}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <CssBaseline />
                                        <Routes />
                                    </SnackbarProvider>
                                </ThemeProvider>
                            </MuiThemeProvider>
                        )}
                    </themeContext.Consumer>
                </StylesProvider>
            </themeContext.Provider>
        </userContext.Provider>
    );
};

const EnhancedApp = withWidth()(App);

const root = document.getElementById('root');

if (root !== null) ReactDOM.render(<EnhancedApp />, root);

serviceWorker.register();
