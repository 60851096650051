import { css } from 'styled-components';

export const flexColumn = css`
    display: flex;
    flex-direction: column;
`;

export const centerItem = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;
