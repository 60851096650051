import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import groupArray from 'group-array';
import isAvailable, { isAvailableMaybe } from 'helpers/isAvailable';
import Pokedex, { Pokemon } from 'pokedex';
import React from 'react';
import { Wrapper } from 'styles/Layout';
import { RatioBox, RatioBoxContent } from 'styles/ratioBox';
import { capitalize } from 'voca';

import { MissingGenRecord } from './components/MissingGenRecord';
import { MissingPie } from './components/MissingPie';
import {
    CountBadge,
    CountBadges,
    GraphBox,
    GraphCore,
    GraphDetails,
    GraphRecord,
    Legend,
    LegendBullet,
    LegendItem,
    PieBox,
    StatCard,
} from './styles';

type PokemonRelevatStatData = {
    generation: number;
    present: boolean;
    leaked: boolean;
};

export const Stats = () => {
    const Dex = new Pokedex();

    const pokemon = new Array(809).fill(null).map((item, index) => {
        const { generation, name } = Dex.pokemon(index + 1);

        return {
            generation,
            present: isAvailable(name),
            leaked: isAvailableMaybe(name),
        };
    });

    const missingStatusCounters = pokemon.reduce<{
        present: PokemonRelevatStatData[];
        missing: PokemonRelevatStatData[];
        leaked: PokemonRelevatStatData[];
    }>(
        (counters, pkmn) => {
            if (pkmn.present) {
                counters.present.push(pkmn);
            } else if (pkmn.leaked) {
                counters.leaked.push(pkmn);
            } else {
                counters.missing.push(pkmn);
            }
            return counters;
        },
        {
            present: [],
            missing: [],
            leaked: [],
        },
    );

    const missingStatusCountersEntries = Object.entries(missingStatusCounters) as [
        'present' | 'missing' | 'leaked',
        PokemonRelevatStatData[],
    ][];

    const groupedByStatus = {
        present: groupArray<PokemonRelevatStatData>(missingStatusCounters.present, 'generation'),
        missing: groupArray<PokemonRelevatStatData>(missingStatusCounters.missing, 'generation'),
        leaked: groupArray<PokemonRelevatStatData>(missingStatusCounters.leaked, 'generation'),
    };
    const groupedByStatusEntries = Object.entries(groupedByStatus) as [
        'present' | 'missing' | 'leaked',
        {
            [groupingKeyValue: string]: {
                [key: string]: PokemonRelevatStatData;
            }[];
        },
    ][];

    return (
        <Wrapper>
            <Container>
                <Box marginTop={8}>
                    <Typography variant="h1">Stats</Typography>
                    <StatCard>
                        <CardHeader title="Missing pokémon percentage" />
                        <CardContent>
                            <Grid container spacing={4} alignItems="stretch">
                                <Grid item xs={12} sm={4} md={5} lg={4}>
                                    <RatioBox w={1} h={1}>
                                        <RatioBoxContent>
                                            <GraphCore>
                                                <Typography variant="h5">National Dex</Typography>
                                                <CountBadges>
                                                    {missingStatusCountersEntries.map(
                                                        ([type, value]) => (
                                                            <CountBadge key={type} type={type}>
                                                                {value.length}
                                                            </CountBadge>
                                                        ),
                                                    )}
                                                </CountBadges>
                                            </GraphCore>
                                        </RatioBoxContent>
                                        <RatioBoxContent>
                                            <MissingPie
                                                data={missingStatusCountersEntries.map(
                                                    ([type, value]) => ({
                                                        id: type,
                                                        label: capitalize(type),
                                                        value: value.length,
                                                    }),
                                                )}
                                            />
                                        </RatioBoxContent>
                                    </RatioBox>
                                </Grid>
                                <Grid item xs={12} sm={8} md={7} lg={8}>
                                    <Box display="flex" flexDirection="column" height="100%">
                                        <Legend>
                                            {['present', 'missing', 'leaked'].map(type => (
                                                <LegendItem key={type}>
                                                    <LegendBullet
                                                        type={
                                                            type as 'present' | 'missing' | 'leaked'
                                                        }
                                                    />
                                                    {capitalize(type)}
                                                </LegendItem>
                                            ))}
                                        </Legend>
                                        <PieBox>
                                            <Grid container spacing={1}>
                                                {Array(7)
                                                    .fill(null)
                                                    .map((val, index) => {
                                                        return (
                                                            <Grid
                                                                key={index}
                                                                item
                                                                xs={4}
                                                                sm={6}
                                                                lg={4}
                                                            >
                                                                <MissingGenRecord
                                                                    data={groupedByStatusEntries}
                                                                    gen={index + 1}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </PieBox>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </StatCard>
                </Box>
            </Container>
        </Wrapper>
    );
};
